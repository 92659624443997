import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import { MetaMaskProvider } from '@metamask/sdk-react'

import store, { persistor } from 'state'
import Updater from 'state/updater'

import Web3Provider from 'components/Web3Provider'
import WalletModal from 'components/WalletModal'

import App from './App'
import reportWebVitals from './reportWebVitals'

import './index.scss'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
)
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Web3Provider>
          <Updater />
          <MetaMaskProvider sdkOptions={{dappMetadata: {name: 'Test', url: window.location.href}}} debug={false}>
            <App />
          </MetaMaskProvider>
          <WalletModal />
        </Web3Provider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
